<template>
  <ul
    class="side-nav"
    :class="{ 'side-nav--mode-icon': displayMode === 'icons' }"
    style="max-height: 100%">
    <slot>
      <transition-group
        enter-active-class="animate__fadeIn animate--fast "
        leave-active-class="display-none">
        <div
          v-if="parentItem"
          :index="'menu-back-link'"
          :data-index="'menu-back-link'"
          class="side-nav__back-link"
          @click="goUpByOneLevel">
          <div class="flex gap-m flex-middle">
            <icon-halo
              theme="bg"
              class="side-nav__back-icon"
              halo-theme="gray-5"
              icon="chevron-inline-start"></icon-halo>
            <span v-if="displayMode !== 'icons'">{{ levelUpText }}</span>
          </div>
        </div>
        <li
          v-for="item in displayItems"
          :key="item.index"
          :data-index="item.index"
          class="side-nav-item"
          :class="{
            'nav-item--mode-icon': displayMode === 'icons',
            'cursor-pointer': item.actionType == false && item.children,
            parent: item.actionType == false && item.children,
          }"
          :item="item"
          @click="itemClickHandler(item)">
          <nav-internal-item
            v-if="item.actionType == 'title'"
            v-bind="{ displayMode }"
            :item="item"
            @click="navigate"></nav-internal-item>
          <saffron-link
            v-if="item.actionType == 'routerNavigation'"
            v-slot="{ navigate }"
            :to="item.actionData.to"
            class="block">
            <nav-internal-item
              v-bind="{ displayMode }"
              :item="item"
              @click="navigate"></nav-internal-item>
          </saffron-link>
          <a
            v-if="item.actionType == 'nativeNavigation'"
            :href="item?.actionData?.href ? item?.actionData?.href : '#'"
            :target="item?.actionData?.target ? item?.actionData?.target : '_self'"
            class="block">
            <nav-internal-item v-bind="{ displayMode }" :item="item"></nav-internal-item>
          </a>
          <span v-if="!item.actionType" class="block">
            <nav-internal-item v-bind="{ displayMode }" :item="item"></nav-internal-item>
          </span>
        </li>
      </transition-group>
    </slot>
  </ul>
</template>

<script>
import NavInternalItem from "@/client/components/nav/NavInternalItem.vue";

export default {
  components: { NavInternalItem },
  props: {
    items: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      displayMode: "full",
      safeItems: {},
      parentItem: false,
      displayItems: {},
    };
  },
  computed: {
    levelUpText() {
      if (!this.parentItem) {
        return "";
      }
      let parentItem = this.getItemByIndex(this.parentItem?.parent);
      if (parentItem?.label) {
        return this.translateUcFirst(parentItem.label);
      }

      return this.translateUcFirst("core.sideNav.backOneLevel");
    },
  },
  watch: {
    items: {
      handler(newItems) {
        let items = { ...newItems };
        let i = 0;
        for (const [key, rootItem] of Object.entries(items)) {
          this.makeItemSafe({ item: rootItem, parent: false, items, index: i });
          i++;

          if (rootItem.children && typeof rootItem.children === "object") {
            for (const [childKey, childItem] of Object.entries(rootItem.children)) {
              this.makeItemSafe({ item: childItem, parent: rootItem, items, index: i });
              i++;
            }
          }
        }

        this.safeItems = items;

        this.showRoot();
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    goUpByOneLevel() {
      // we are at root
      if (!this.parentItem) {
        this.showRoot();
      }

      // we are at level 1, which means up is root
      if (this.parentItem.level === 1) {
        this.showRoot();
      }

      // we are deep
      let targetItemIndex = this.parentItem.parent;
      let targetItem = this.getItemByIndex(targetItemIndex);

      // do not blow up in any case
      if (!targetItem) {
        this.showRoot();
      }

      this.parentItem = targetItem;
      return true;
    },
    makeItemSafe({ item, parent, index, items }) {
      item.index = index;

      if (parent) {
        item.level = parent.level + 1;
      } else {
        item.level = 0;
      }

      item.expanded = false;

      if (parent) {
        item.parent = parent.index; //rootItem;
      }

      if (!item.action) {
        item.action = false;
        item.actionType = false;
        item.actionData = false;
      } else {
        item.actionType = item.action.type;
        item.actionData = item.action.data;
      }

      if (this.displayMode === "icons") {
        // item.label = false;
      }

      if (typeof item.autoTranslate === "undefined") {
        item.autoTranslate = true;
      }
    },
    itemClickHandler(item) {
      // show children handler
      if (item.actionType === false && item.children) {
        this.showItem(item);
        return;
      }

      if (item.actionType === "function") {
        item.actionData["function"]({ item, ...item.actionData });
      }
    },
    getItemByIndex(index, item) {
      let res = null;
      let targetItems = item && item.children ? item.children : this.safeItems;
      for (const [key, item] of Object.entries(targetItems)) {
        if (item.index === index) {
          res = item;
          return res;
        }

        if (item.children) {
          let childRes = this.getItemByIndex(index, item);
          if (childRes) {
            return childRes;
          }
        }
      }

      return false;
      // todo implement
      // todo: implement "nested" display mode, with back navigation
    },
    showItem(itemIndex) {
      let targetIndex = typeof itemIndex === "object" ? itemIndex.index : itemIndex;
      let targetItem = this.getItemByIndex(targetIndex);
      if (!targetItem.children) {
        return true;
      }

      this.parentItem = targetItem;
      this.displayItems = targetItem.children;
    },
    showRoot() {
      this.displayItems = this.safeItems;
      this.parentItem = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
